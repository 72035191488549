import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,
  form: {
    ...StandardForm.form,
    questions: {
      purposeOfCustomerRelationship: {
        focused: true,
        section: 'engagement',
        type: 'longtext',
        label:
          'Beskriv hvad du skal bruge banken til i din hverdag, herunder hvordan du bruger dine konti, kort mm.',
        placeholder:
          'Hvad bruger du {name} til? (Indtast minimum 10 karakterer)',
        required: true,
        minLength: 10,
        /* matches when sting contains atleast 10 char,
        numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },

      customerEngagementOtherBanks: {
        label: 'Har du konti i andre pengeinstitutter ?'
      },
      assetsAmount: {
        label: 'Hvor stor er din samlede netto formue?'
      },

      // cashDepositAmount: {
      //   label:
      //     'Hvor mange kontanter forventer du at indsætte årligt i bankens pengeautomater?',
      //   options: [
      //     {
      //       value: 24999,
      //       label: '0 - 24.999'
      //     },
      //     {
      //       value: 49999,
      //       label: '25.000 - 49.999'
      //     },
      //     {
      //       value: 99999,
      //       label: '50.000 - 99.999'
      //     },
      //     {
      //       value: 100000,
      //       label: '100.000 og derover'
      //     }
      //   ]
      // },

      cashDepositComment: {
        label: 'Beskriv årsagen til valgte interval for kontante indsættelser',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      // cashWithdrawalAmount: {
      //   label:
      //     'Hvor mange kontanter forventer du at hæve årligt i bankens pengeautomater?',
      //   options: [
      //     {
      //       value: 49999,
      //       label: '0 - 49.999'
      //     },
      //     {
      //       value: 99999,
      //       label: '50.000 - 99.999'
      //     },
      //     {
      //       value: 199999,
      //       label: '100.000 - 199.999'
      //     },
      //     {
      //       value: 200000,
      //       label: '200.000 og derover'
      //     }
      //   ]
      // },
      cashWithdrawalComment: {
        label: 'Beskriv årsagen til valgte interval for kontante hævninger',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      foreignAssociated: {
        label:
          'Har du tilknytning til andre lande end Danmark i form af tilbagevende overførsler fra udlandet? F.eks. ved modtagelse af løn fra arbejde, lejeindtægter fra feriebolig, overførsler fra familie o. lign. i udlandet.'
      },
      // foreignReceiveAmount: {
      //   options: [
      //     {
      //       value: 9999,
      //       label: '0 - 9.999'
      //     },
      //     {
      //       value: 49999,
      //       label: '10.000 - 49.999'
      //     },
      //     {
      //       value: 99999,
      //       label: '50.000 - 99.999'
      //     },
      //     {
      //       value: 249999,
      //       label: '100.000 - 249.999'
      //     },
      //     {
      //       value: 499999,
      //       label: '250.000 - 499.999'
      //     },
      //     {
      //       value: 500000,
      //       label: '500.000 og derover'
      //     }
      //   ]
      // },
      foreignReceiveComment: {
        label:
          'Beskriv årsagen til valgte interval for indgående udenlandske overførsler',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
        numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      // foreignTransferAmount: {
      //   options: [
      //     {
      //       value: 49999,
      //       label: '0 - 49.999'
      //     },
      //     {
      //       value: 99999,
      //       label: '50.000 - 99.999'
      //     },
      //     {
      //       value: 199999,
      //       label: '100.000 - 199.999'
      //     },
      //     {
      //       value: 200000,
      //       label: '200.000 og derover'
      //     }
      //   ]
      // },
      foreignTransferComment: {
        label:
          'Beskriv årsagen til valgte interval for udgående udenlandske overførsler',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
        numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      employmentOtherComment: {
        section: 'income',
        type: 'shorttext',
        label: 'Beskriv ansættelsesforhold',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/,
        dependencies: {
          type: 'every',
          conditions: {
            employmentType: {
              expression: 'includes',
              value: ['Andet']
            }
          }
        }
      },
      annualIncomeComment: {
        section: 'income',
        type: 'longtext',
        label: 'Beskriv hvilke andre indtægter',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/,
        dependencies: {
          type: 'every',
          conditions: {
            annualIncomeOther: {
              expression: 'equals',
              value: 'Yes'
            }
          }
        }
      },
      assetsComment: {
        section: 'income',
        type: 'longtext',
        label: 'Hvor stammer din formue fra?',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
   numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      foreignAssociatedCountriesComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv tilknytning til udlandet',
        required: true,
        placeholder: 'Indtast minimum 10 karakterer',
        minLength: 10,
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/,
        dependencies: {
          type: 'every',
          conditions: {
            foreignAssociatedCountries: {
              expression: 'not_includes',
              value: ['']
            }
          }
        }
      }
      // customerEngagementDenmarkComment: {
      //   section: 'engagement',
      //   type: 'longtext',
      //   label: 'Beskriv baggrunden for valg af flere pengeinstitutter ',
      //   required: true,
      //   placeholder: 'Indtast minimum 10 karakterer',
      //   minLength: 10,
      //   /* matches when string contains atleast 10 char,
      // numbers or letter regardless of posistion in the string*/
      //   pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/,
      //   dependencies: {
      //     type: 'every',
      //     conditions: {
      //       customerEngagementLocations: {
      //         expression: 'includes',
      //         value: ['DK']
      //       }
      //     }
      //   }
      // },
      // customerEngagementAbroadComment: {
      //   section: 'engagement',
      //   type: 'longtext',
      //   label: 'Beskriv baggrunden for valg af udenlandsk pengeinstitut',
      //   required: true,
      //   minLength: 10,
      //   placeholder: 'Indtast minimum 10 karakterer',
      //   /* matches when string contains atleast 10 char,
      // numbers or letter regardless of posistion in the string*/
      //   pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/,
      //   dependencies: {
      //     type: 'every',
      //     conditions: {
      //       customerEngagementLocations: {
      //         expression: 'includes',
      //         value: ['Abroad']
      //       }
      //     }
      //   }
      // }
    }
  }
};

export default mergeObjects(StandardForm, customizations);
