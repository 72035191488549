import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Skjern Bank',
  bankId: 9,
  logoWidth: 200,
  color: '#e30405',
  googleAnalytics: {
    test: 'UA-53256967-16',
    production: 'UA-53256967-4'
  },
  forceOverride: false,
  backgroundGradient: ['#efefef', '#efefef'],
  backgroundImage: '9_bg.png',
  topBar: { background: '#fff', color: '#000' },
  body: { headlineColor: '#000', bodyColor: '#000' }
};

export default config;
